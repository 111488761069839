<template>
  <div>
    <h1>Submit a Post...</h1>
    <iframe
      scrolling="no"
      id="submit_form"
      src="https://www.tumblr.com/submit_form/winandjake.tumblr.com"
      style="background-color: transparent; overflow: hidden"
      width="100%"
      height="530"
      frameborder="0"
    ></iframe>
  </div>
</template>

<script>
export default {
  name: "SubmitAPost",
};
</script>
