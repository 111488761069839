<template>
  <div>
    <h1>Ask us Anything...</h1>
    <iframe
      scrolling="no"
      src="https://www.tumblr.com/ask_form/winandjake.tumblr.com"
      style="background-color: transparent; overflow: hidden; height: 190px"
      id="ask_form"
      width="100%"
      height="190"
      frameborder="0"
    ></iframe>
  </div>
</template>

<script>
export default {
  name: "AskUsAnything",
};
</script>
